import React , {useState} from 'react'
import Modal from 'react-bootstrap/Modal';
import './sale.css'
import Button from 'react-bootstrap/Button';
import { ToastContainer } from 'react-toastify';
import TextField from '@mui/material/TextField';
function Citymodel({show,onHide,user,current_user,route,callagain}) {
    
    

    
      const [name , setname] = useState('')
    const handleClick=async(e)=>{
          e.preventDefault()
         
            
          const formData = new FormData();
          formData.append('name', name);
          formData.append('user',current_user.id)
          
          const response= await fetch(`${route}/api/city/`,{
              method: "POST",
              headers: { 
                Authorization : `Bearer ${user.access}`
               },
              body: formData,
          })
          const json=await response.json();
          if (response.ok){
            callagain()
            onHide()
          }
    }
    
    
    return (
      <Modal
        show={show}
        onHide={onHide}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        style={({zoom:'.8'})}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className='d-flex align-items-md-center'>
            Add City
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <TextField  className='form-control   mb-3' id="outlined-basic"  label='Name of City'  value={name} onChange={(e)=>{setname(e.target.value)}} size='small'/>
        <hr></hr>
        <div className=' d-flex flex-row-reverse mt-2 me-2'>
        <Button type='button' variant='outline-primary' onClick={handleClick} > Save</Button>

        </div>
        </Modal.Body>
      </Modal>
    );
    
    }

export default Citymodel


    