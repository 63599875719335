import React,{useState , useRef} from 'react'
import './home.css'
import { Sidebar, Menu, MenuItem,SubMenu, useProSidebar } from "react-pro-sidebar";
import {Outlet } from 'react-router-dom'
import { Avatar } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu';
import PersonIcon from '@material-ui/icons/Person';
import DashboardIcon from '@material-ui/icons/Dashboard';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import Header from '../Container/headerContainer';
import Footer from '../Components/footer';
import HistoryIcon from '@material-ui/icons/History';
import CategoryIcon from '@material-ui/icons/Category';
import TurnedInIcon from '@material-ui/icons/TurnedIn';
import AssessmentIcon from '@material-ui/icons/Assessment';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { faTag } from "@fortawesome/free-solid-svg-icons";



function Layout(props) {
 
  const user = props.state.setuser.user
  const menu_status=props.state.Settablehistory.menu_status
  const current_user = props.state.Setcurrentinfo.current_user
  const { collapseSidebar,toggleSidebar,broken,collapsed } = useProSidebar()
  const [checkcollapse , setcheckcollapse] = useState(true)
  
  const handlemouseleave=()=>{
    if (checkcollapse && !collapsed) {
      collapseSidebar()
     

    }
  }

  const handlemouseenter=()=>{
    if (checkcollapse && collapsed) {
      collapseSidebar()
     

    }
  }
  return (
    <div id="app" className='layout d-flex' style={({zoom:'.8'})}>
      
      {user && <Sidebar breakPoint='md' defaultCollapsed={true} rootStyles={{ color:'whitesmoke' }}    backgroundColor='#333'>
        <div style={{ textAlign: "center",borderBottom: '1px solid #dee2e6',display:'flex', alignItems:'center' }}>
          <Avatar  className='mt-1 mb-1 ms-3 me-4'/>
          <h5>{current_user && current_user.username}</h5>
        </div>
        <Menu  onMouseEnter={handlemouseenter} onMouseLeave={handlemouseleave} style={{height: '117vh',overflow:'auto'}}
         
         menuItemStyles={{
          button: ({ level, active, disabled }) => {
            
            if (level === 0 || level === 1) {
              return {
                color: active ? 'skyblue' : undefined,
                "&:hover": {
                   backgroundColor: "#335B8C !important",
                   color: "white !important",
                   borderRadius: "8px !important",
                   fontWeight: "bold !important"
                 },
              };
            }
          },
         }}
         >
          
          <MenuItem active={menu_status==='dashboard'} icon={<DashboardIcon />} component={<Link to="/dashboard" />}>Dashboard</MenuItem>

          {current_user && <>{current_user.id==1 && <SubMenu active={menu_status==="user"}  label='Basic Menu' icon={<MenuIcon />}>
            <MenuItem active={menu_status==="user"} icon={<PersonIcon/>} component={<Link to="/user" />} rootStyles={{color:'whitesmoke' , backgroundColor:'#333'}}> User </MenuItem>


          </SubMenu>}</>}
           

          

          <SubMenu active={menu_status==="products" || menu_status==="categories"} label={'Products'} icon={<FontAwesomeIcon  icon={faTag} size="lg" />} >
            <MenuItem active={menu_status==="categories"} icon={<CategoryIcon/>} component={<Link to="/categories" />} rootStyles={{color:'whitesmoke' , backgroundColor:'#333'}}> Categories</MenuItem>
            <MenuItem active={menu_status==="products"} icon={<TurnedInIcon/>} component={<Link to="/products" />} rootStyles={{color:'whitesmoke' , backgroundColor:'#333'}}> Products </MenuItem>
          </SubMenu>
          
          
          <SubMenu active={menu_status==="sale" || menu_status==="sale_history"} label={'Booking'} icon={<LocalAtmIcon />} >
            <MenuItem active={menu_status==="sale"} icon={<LocalAtmIcon/>} rootStyles={{color:'whitesmoke' , backgroundColor:'#333'}} component={<Link to="/sale" />}> Booking</MenuItem>          
            <MenuItem active={menu_status==="sale_history"} icon={<HistoryIcon/>} component={<Link to="/sale_history" />} rootStyles={{color:'whitesmoke' , backgroundColor:'#333'}}> Booking History</MenuItem>          
         
          </SubMenu>

          

          
          <MenuItem active={menu_status==="sale_report"} icon={<ReceiptIcon/>} rootStyles={{color:'whitesmoke' , backgroundColor:'#333'}} component={<Link to="/sale_report" />}> Reports </MenuItem>          
         
          

        </Menu>
      </Sidebar>}
      
      
      
      
      
      <div className='content w-100 ' >
          <div className='header '>
            {user && <Header 
            togglefun={toggleSidebar}
            collapsefun={collapseSidebar}
            broken={broken}
            statefun={()=>setcheckcollapse(!checkcollapse)}

            />}
          </div>

          {user ? <div  style={{minHeight:'108vh', backgroundColor:'rgb(241, 245, 245)'}} > 
              <Outlet/>   
          </div>:<div  style={{minHeight:'125vh',backgroundColor:'rgb(241, 245, 245)'}} > 
              <Outlet/>   
          </div>}
        
        <div className='footer'>
          {user && <Footer/>}
        </div>
      </div>
      
    </div>
  )
}

export default Layout