import React,{useState , useEffect} from 'react'
import Modal from 'react-bootstrap/Modal';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search , CSVExport } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Button from 'react-bootstrap/Button';
import { Avatar } from '@material-ui/core';
import './sale.css'
import { ToastContainer } from 'react-toastify';
import went_wrong_toast from '../alerts/went_wrong_toast';
import Spinner from 'react-bootstrap/Spinner'
import Select from "react-select";
import custom_toast from '../alerts/custom_toast';
import Red_toast from '../alerts/red_toast';

function Saleform({show,onHide,user,route1,callback,text,counter,setcounter,setsupplier,setdeliveryman,setsaleman,table_data,vat_perc,current_user,route,data_}) {
    
  const [isloading , setisloading] = useState(false)
  const [data, setdata] = useState(data_)
  const { SearchBar } = Search;
  const [callonce, setcallonce] = useState(false)
  const [category , setcategory] = useState({value:'all', label:'All'})
  const [allcategory , setallcategory] = useState([])
  const [subcategory , setsubcategory] = useState({value:'all', label:'All'})
  const [allsubcategory , setallsubcategory] = useState([])

  const [callagain , setcallagain] = useState(false)

  useEffect(() => {
     
    const fetchCategory = async () => {
      const response = await fetch(`${route}/api/categories/?user_id=${current_user.id}`, {
        headers: { Authorization: `Bearer ${user.access}` },
      });
      const json = await response.json();

      if (response.ok) {
        const optimize = json.map((item)=>{
          return {value:item.id, label:item.name}
        })
        optimize.splice(0,0,{value:'all' , label:'All'})
        setallcategory(optimize)
       
        
      }
      
    };

      fetchCategory() 
    
    
  }, []);

  useEffect(() => {
    const fetchWorkouts = async () => {
      var url=`${route1}`
      
      if (category.value!='all'){
        url=`${url}&category_id=${category.value}`
      }
      
  
      const response = await fetch(`${url}`, {
        headers: { Authorization: `Bearer ${user.access}` },
      });
      const json = await response.json();

      if (response.ok) {
        setisloading(false)
        setdata(json)
        
        
      }
      if (!response.ok){
        went_wrong_toast()
        setisloading(false)
      }
    };

    if (callonce) {
      fetchWorkouts(); 
    } 
    }, [callagain]);

    const headerstyle = (column , colIndex,  {sortElement})=>{
      return(
        <div className='d-flex justify-content-between align-items-center' style={{minHeight:'2.5rem'}}>
          {column.text}
          {sortElement}
        </div>
      )
    }

    const loadimage = (cell, row, rowIndex, formatExtraData) =>{
    
      return(
        <div className='d-flex justify-content-center'  >
          <Avatar src={cell} alt='logo'/>
        </div>
      )
  }

  
  
    
    
    
       const columns = [
        { dataField: 'id', text: 'Id',hidden: true,headerFormatter:headerstyle,csvExport: false},
          { dataField: 'images[0].picture', text: 'Pic', formatter:loadimage, headerFormatter:headerstyle,csvExport: false},
          { dataField: 'name', text: 'Name',sort: true, headerFormatter:headerstyle},
          { dataField: 'category_name', text: 'Category',sort: true, headerFormatter:headerstyle},    
          { dataField: 'status', text: 'Status',sort: true,headerFormatter:headerstyle},
        ];
        
          const selectRow = {
            mode: 'checkbox',
            clickToSelect: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                if (isSelect){

                  const item_present=table_data.filter((item)=>{
                    return item.product===row.id
                })
                
                if (item_present.length===0){

                  var filter_data=data.filter((item)=>{
                    if (item.id===row.id){
                        return item
                    }
                })
                
                
                  filter_data=filter_data.map((item)=>{
                    return {
                      product:item.id,
                        prod_id:counter,
                        name:item.name,
                        quantity:1,
                        price:0,
                        total:0,
                    }
                })
                    
                
                callback({type:'Create_product_history',data: filter_data[0]})
                custom_toast('Item added')
                setcounter(counter+1)
              }else {
                Red_toast(`Product Already  Added !`)
            }
                
              
            }},
            onSelectAll: (isSelect, rows, e) => {
              
                if (isSelect){
                    var num=counter
                    

                    rows.forEach((item)=>{

                    const item_present=table_data.filter((prod)=>{
                        return prod.product===item.id
                    })
                    
                    if (item_present.length===0){

                      var filter_data=data.filter((row)=>{
                        if (item.id===row.id){
                            return item
                        }
                    })
                    
                    
                      filter_data=filter_data.map((item)=>{
                        return {
                          product:item.id,
                            prod_id:num,
                            name:item.name,
                            quantity:1,
                            price:0,
                            total:0,
                        }
                    })
                        
                    num=num+1
                    callback({type:'Create_product_history',data: filter_data[0]})
                    
                  }else {
                    Red_toast(`Product Already  Added !`)
                }
                    
                    })
                    setcounter(num)   
                    custom_toast('Items added')
                }}
            }
    

    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total ms-2">
          Showing { from } to { to } of { size } Results
      </span>
  );

  const options = {
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'First',
      showTotal: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
      sizePerPageList: [{
          text: '10', value: 10
      }, {
          text: '20', value: 20
      }, {
          text: 'All', value: data.length
      }] // A numeric array is also available. the purpose of above example is custom the text
  };
  
  const rowstyle = { height:'10px'}

  const selectStyles = {
    menu: base => ({
      ...base,
      zIndex: 100
    })
  };

  useEffect(()=>{
    setcallagain(!callagain)

  },[category,subcategory])
    
  const handlecategory=(e)=>{
    setcategory(e)
    

  }

  const handlesubcategory=(e)=>{
    setsubcategory(e)
    
  }
  
    
  
  
  
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      style={{zoom:'0.85'}}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className='d-flex align-items-md-center'>
          {text}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>

      <ToolkitProvider
              keyField="id"
              data={ data }
              columns={ columns }
              search
              exportCSV
              
              
              >
              {
                  props => (
                      
                  <div>
                      
                      <div className='d-flex  justify-content-between align-items-center mt-3'>
                        <div className=' col-sm-3 d-flex'>
                          
                          <div className='d-flex flex-column me-3 form-control  selector mb-3'>
                            <label>Category</label>
                            <Select   className=' form-control  selector' styles={selectStyles} options={allcategory} placeholder='Categories' value={category} onChange={handlecategory} ></Select>
                          </div>
                          
                        </div>
                            <SearchBar { ...props.searchProps } />
                        </div>
                      
                      {isloading && <div className='text-center'><Spinner animation="border" variant="primary" /></div>}

                      <hr/>
                      <div style={{height: '60vh',overflow:'auto'}}>
                      <BootstrapTable
                      { ...props.baseProps }
                      pagination={paginationFactory(options)}
                      rowStyle={ rowstyle}
                      striped
                      bootstrap4
                      condensed
                      selectRow={ selectRow }
                      wrapperClasses="table-responsive"
                      classes='table_class'
                      />
                      </div>
                  </div>
                  )
              }
          </ToolkitProvider>
     
      </Modal.Body>
    </Modal>
  );
    
    }

export default Saleform


    