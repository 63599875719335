import { connect } from "react-redux";
import Categories from "../Components/categories/categories";
import { Settablehistory ,removeuser} from "../Service/Actions/action";

const dispatchpart=dispatch=>({
    Settable_history:data=>dispatch(Settablehistory(data)),
    RemoveUser:()=>dispatch(removeuser()),
})
    
const getpart=state=>({
        state
    })
    
export default connect(getpart,dispatchpart)(Categories)