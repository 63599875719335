import React, { useEffect, useState } from "react";
import "./product.css";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { IconButton } from "@material-ui/core";
import { Link } from "react-router-dom";

function Productwidget({ img, name, category, status, description, id }) {
  const [image, setimage] = useState("");
  const [all_image, setall_image] = useState("");
  useEffect(() => {
    if (img.length > 0) {
      setall_image(
        img.map((item) => {
          return item.picture;
        })
      );
      setimage(img[0].picture);
    }
  }, []);

  return (
    <Link to={`/singleproductdetail/${id}`} className="col-sm-3 p-0">
      <div className=" upper-div p-2 mb-4">
        <div className="text-center" style={{ height: "4in" }}>
          <img src={image} alt="image" width={300} height={400}></img>
        </div>
        <div className="d-flex justify-content-between">
          <p className="text-dark mt-3 mb-0 ">
            <strong>{name.toUpperCase()}</strong>
          </p>
          <p
            className={
              status.toLowerCase() === "available"
                ? "text-success m-0 mt-3"
                : "text-danger m-0 mt-3"
            }
          >
            {status.toUpperCase()}
          </p>
        </div>

        <p className="text-dark m-0">{category}</p>
        <p className="text-primary mt-3">{description}</p>
      </div>
    </Link>
  );
}

export default Productwidget;
