import React,{useState,useEffect,useRef} from 'react'
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer } from 'react-toastify';
import TextField from '@mui/material/TextField';
import { Avatar } from '@material-ui/core';
import went_wrong_toast from '../alerts/went_wrong_toast';
import Update_button from '../buttons/update_button';
import { useTranslation } from 'react-i18next';

function Userupdate({show,onHide,data,user,route,fun,callback}) {
  const { t } = useTranslation()
    const inputFile = useRef(null)
    const [picture , setpicture] = useState('')
    const [Fileurl , setFileurl] = useState('')
    const [isloading , setisloading] = useState(false)
    const [username,setusername] = useState(data.username)
    const [email,setemail] = useState(data.email)
    const [password,setpassword] = useState('')
    const [phone_number,setphone_number] = useState('')
    const [address,setaddress] = useState('')
    const [title,settitle] = useState('')
    const [terms, setterms] = useState('')

    useEffect(()=>{
      if (data.profile) {
        setphone_number(data.profile.phone_number)
        setaddress(data.profile.address)
        settitle(data.profile.title)
        setterms(data.profile.terms)
        setFileurl(data.profile.picture)

      
    }
    },[])
    

    
    
    

    const handleSubmit=async(e)=>{
        e.preventDefault()
        setisloading(true)
        const formData = new FormData();
        
        
        if (password.length>0){
          formData.append('username', username);
          formData.append('email', email);
          formData.append('password', password);
          
          formData.append('profile.address', address);
          formData.append('profile.phone_number', phone_number);
          formData.append('profile.title', title);
        }else{
          formData.append('username', username);
          formData.append('email', email);
          
          formData.append('profile.address', address);
          formData.append('profile.phone_number', phone_number);
          formData.append('profile.title', title);
          formData.append('profile.terms', terms);
        }
        if (picture){
          formData.append('profile.picture', picture);
        }
        
        const response = await fetch(`${route}/api/users/${data.id}/`,{
            method: "PATCH",
            headers: { 
              Authorization : `Bearer ${user.access}`
             },
            body: formData,
        })
        const json=await response.json();
        
        if (!response.ok){
            setisloading(false)
            went_wrong_toast()
            
        }

        if (response.ok){
            setisloading(false)
            callback({type:'Update_table_history',data: json})
            localStorage.setItem('current_user' , JSON.stringify(json))
            onHide()
            fun('Update')

        }

    }

    const onButtonClick = () => {
      inputFile.current.click();
    };

    const handleimageselection =(event)=>{
      console.log(event.target)
      if (event.target.files){
        const file = event.target.files[0];
      
      setpicture(file);
     

      const reader = new FileReader();
      reader.onload = () => {
        setFileurl(reader.result);
     
      };
      reader.readAsDataURL(file);
      }
    }

    return (
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        style={{zoom:'0.85'}}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className='d-flex align-items-center'>
            <PersonAddIcon className='me-2'/>
            Edit User
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
        <form onSubmit={handleSubmit}>
        <div className='row'>
          <div className='col-md-2 d-flex flex-column align-items-center'>
                <Avatar src={Fileurl} className='mb-3' style={{width:'100px', height:'100px'}} alt='image' variant='rounded'/>
                <input
                  onChange={handleimageselection}
                  id="select-file"
                  type="file"
                  ref={inputFile}
                  style={{display: 'none'}}
              />
              <Button onClick={onButtonClick} shadow>
              Choose file
              </Button>
            </div>

            <div className='col-md-10'>
              <div className='d-flex'>
              <div className='col-md-6'>
                  <TextField className='form-control me-2   mb-3' id="outlined-basic" label="Username"  value={username} onChange={(e)=>{setusername(e.target.value)}} size='small' required/>
                  <TextField type='email' className='form-control me-2   mb-3' id="outlined-basic" label="Email"  value={email} onChange={(e)=>{setemail(e.target.value)}} size='small' required/>
                  <TextField type='password' className='form-control me-2  mb-3' id="outlined-basic" label="Password"  value={password} onChange={(e)=>{setpassword(e.target.value)}} size='small'/>
              </div>
              <div className='col-md-6 ms-2'>
                  <TextField className='form-control me-2   mb-3' id="outlined-basic" label="titel"  value={title} onChange={(e)=>{settitle(e.target.value)}} size='small' />
                  <TextField className='form-control me-2  mb-3' id="outlined-basic" label="Phone No."  value={phone_number} onChange={(e)=>{setphone_number(e.target.value)}} size='small' />
                  <TextField multiline className='form-control me-2  mb-3' id="outlined-basic" label="Address"  value={address} onChange={(e)=>{setaddress(e.target.value)}} size='small' />

              </div>
              </div>
              <div className=''>
              <TextField multiline rows={3} className='form-control   mb-3' id="outlined-basic" label="Terms & Condition"  value={terms} onChange={(e)=>{setterms(e.target.value)}} size='small' />
              </div>
    
            </div>
                


          </div >
            
            <hr/>
            <div className='d-flex flex-row-reverse mt-2 me-2'>
            <Update_button isloading={isloading}/>
            </div>
        </form>
       
        </Modal.Body>
      </Modal>
    );
  }


export default Userupdate


    