import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { useReactToPrint } from "react-to-print";
import QRCode from "react-qr-code";
import numberToWords from "number-to-words";
import "./sale_invoice.css";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import RoomIcon from "@material-ui/icons/Room";
import DraftsIcon from "@material-ui/icons/Drafts";

function Invoice(props) {
  const { name } = useParams();
  const [company, setcompany] = useState(
    JSON.parse(localStorage.getItem("current_user"))
  );
  const response = JSON.parse(localStorage.getItem("data"));
  const [data, setdata] = useState(response);
  const optimize = response.details.map((item, index) => {
    item["sr"] = index + 1;
    return item;
  });
  const [details, setdetails] = useState(optimize);

  const name_column_formater = (cell, row) => {
    return (
      <div className="d-flex justify-content-between align-items-center pe-0">
        <div>{cell}</div>
        <div>
          <QRCode
            value={`https://rental.eavenir.com/singleproductdetail/${row.product}`}
            bgColor={"rgb(209, 214, 213)"}
            fgColor={"#000000"}
            size={60}
          />
        </div>
      </div>
    );
  };

  const columns = [
    { dataField: "sr", text: "#" },
    {
      dataField: "product_name",
      text: "Product Detail",
      formatter: name_column_formater,
    },
    { dataField: "quantity", text: "Qty" },
    { dataField: "amount", text: "Unit Price" },
    { dataField: "amount", text: "Total" },
  ];

  const componentRef = useRef();
  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    bodyClass: "printclass",
    onAfterPrint: () => {
      window.close();
    },
  });

  useEffect(() => {
    if (data) {
      handleprint();
    }
  }, [data]);

  const slicedata = (date) => {
    const mydate = date.split("-");
    return `${mydate[2]}/${mydate[1]}/${mydate[0]}`;
  };

  return (
    <div className="mydiv" ref={componentRef}>
      <div
        className=" m-0 me-5 p-0 header_1"
        style={{
          backgroundColor: "#661b1c",
          height: "80px",
          position: "absolute",
          width: "60%",
        }}
      ></div>
      <div
        className=" m-0 me-5 p-0 header_2"
        style={{
          backgroundColor: "#913a3b",
          height: "100px",
          position: "absolute",
          width: "55%",
        }}
      ></div>
      {data && (
        <>
          <div className="d-flex align-items-center">
            <div
              className="col-6 m-0 me-5 p-0 header_0"
              style={{ backgroundColor: "#000", height: "120px" }}
            >
              <div className="m-2">
                <img
                  src={company.profile && company.profile.picture}
                  alt="logo"
                  width={"200"}
                  height={"100"}
                ></img>
              </div>
            </div>
            <div className="ms-5">
              <h3 className="mb-2 text-center" style={{ color: "#661b1c" }}>
                <strong>INVOICE</strong>
              </h3>
              <p className="d-flex m-0 " style={{ fontSize: "0.8rem" }}>
                <div className="col-6 m-0">Date : </div>
                <div className="col-9">{data.date}</div>
              </p>
              <p className="d-flex m-0 " style={{ fontSize: "0.8rem" }}>
                <div className="col-9 m-0">Booking No : </div>
                <div className="col-6 text-center">{data.booking_number}</div>
              </p>
            </div>
          </div>

          {/* <hr style={{opacity:'1'}}/> */}
          <div
            className="d-flex justify-content-between m-2 mt-3 p-2"
            style={{ minHeight: "1.3in" }}
          >
            <div className="col-sm-7 me-2 ">
              <h6 className="mb-2" style={{ color: "#661b1c" }}>
                Invoice To:
              </h6>
              <h5 className="m-0">{data.customer}</h5>
              <p className="d-flex m-0" style={{ fontSize: "0.8rem" }}>
                <div className="col-3">Contact : </div>
                <div className="col-9">{data.contact}</div>
              </p>

              <p className="d-flex m-0" style={{ fontSize: "0.8rem" }}>
                <div className="col-3">Address : </div>
                <div className="col-9">{data.address}</div>
              </p>
            </div>
            <div className="col-sm-5 pt-2">
              <br></br>
              <p className="d-flex m-0" style={{ fontSize: "0.8rem" }}>
                <div className="col-6">Order Date : </div>
                <div className="col-6 text-end">
                  {slicedata(data.from_date)}
                </div>
              </p>
              <p className="d-flex m-0">
                <div className="col-6" style={{ fontSize: "0.8rem" }}>
                  Return Date :
                </div>
                <div className="col-6 text-end" style={{ fontSize: "0.8rem" }}>
                  {slicedata(data.to_date)}
                </div>
              </p>
            </div>
          </div>

          <ToolkitProvider keyField="id" data={details} columns={columns}>
            {(props) => (
              <div className="m-2 ms-3" style={{ minHeight: "2.6in" }}>
                <BootstrapTable
                  {...props.baseProps}
                  bordered={true}
                  bootstrap4
                  condensed
                  striped
                  classes="tabel-class"
                />
              </div>
            )}
          </ToolkitProvider>

          <div className="m-2 ms-3 d-flex justify-content-between">
            <div className="col-8">
              <p className="col-11 ">
                <strong>Remarks : </strong> {data.remarks}
              </p>
            </div>

            <div className="col-4">
              <h6 className="d-flex  p-1">
                <div className="col-6">Total : </div>
                <div className="col-6 text-center">{data.total}</div>
              </h6>
              <h6 className="d-flex  p-1">
                <div className="col-6">Received : </div>
                <div className="col-6 text-center">{data.amount_received}</div>
              </h6>
              <h6
                className="d-flex  p-1"
                style={{ backgroundColor: "#661b1c", color: "white" }}
              >
                <div className="col-6">Dues : </div>
                <div className="col-6 text-center">
                  {data.total - data.amount_received}
                </div>
              </h6>
            </div>
          </div>

          <div className=" ms-3" style={{ minHeight: "1.8in" }}>
            <div>
              <h6 className="lh-1 m-0">Terms & Conditions</h6>
              <p>{company.profile.terms}</p>
            </div>
            <div className="d-flex justify-content-end text-end">
              <p
                className="col-4 m-0 text-start"
                style={{ borderTop: "1px solid black" }}
              >
                Customer Signature
              </p>
            </div>
          </div>

          {company.profile && (
            <div
              className="p-2 pe-1 row"
              style={{ backgroundColor: "#000", color: "white" }}
            >
              <p
                className="m-0 col-4 d-flex"
                style={{ fontSize: "0.7rem", fontWeight: "normal" }}
              >
                <WhatsAppIcon className="me-2" />
                <strong>{company.profile.phone_number}</strong>
              </p>
              <p
                className="m-0 col-4"
                style={{ fontSize: "0.7rem", fontWeight: "normal" }}
              >
                <DraftsIcon className="me-2" />
                {company.email}
              </p>
              <p
                className="m-0 col-4 d-flex"
                style={{ fontSize: "0.7rem", fontWeight: "normal" }}
              >
                <RoomIcon className="me-2" />
                <span>{company.profile.address}</span>
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default Invoice;
