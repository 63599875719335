import { Set_User } from "../constants"


const token = JSON.parse(localStorage.getItem("user"));

if (token){
    var user_token=token
}else{
    var user_token=null
}

const initialstates={
    user:user_token,
    route:'https://rentalapi.eavenir.com',

}

export default function setuser(state=initialstates,action){
    
    switch (action.type){
        case Set_User:
            return {
                route:state.route,
                user:action.payload
            }
        
        case "Remove_User":
            localStorage.removeItem("user");
            localStorage.removeItem("current_user");
            localStorage.removeItem("data");
         
            return {
                route:state.route,
                user:null
            }
        default:
            return {
                ...state
            }
            
    }
}