import {SetCurrentUser,Set_Branch_first,Set_Branch } from "../constants"

const branch = JSON.parse(localStorage.getItem("current_user"));

if (branch){
    var user_branch=branch
}else{
    var user_branch=null
}

const initialstates={
    current_user:user_branch,
    
    

}
export default function Setcurrentinfo(state=initialstates,action){
   
    switch (action.type){

        case SetCurrentUser:
            return {
                current_user:action.payload
            }
        default:
            return {
                ...state
            }
            
    }
}