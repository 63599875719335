import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import "./product.css";
import Select from "../alerts/select";
import { IconButton, Avatar } from "@material-ui/core";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { ToastContainer } from "react-toastify";
import custom_toast from "../alerts/custom_toast";
import went_wrong_toast from "../alerts/went_wrong_toast";
import Spinner from "react-bootstrap/Spinner";
import Alert_before_delete from "../../Container/alertContainer";
import Productform from "./productform";
import Productupdate from "./updateproductform";
import ApartmentIcon from "@material-ui/icons/Apartment";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import PrintIcon from "@material-ui/icons/Print";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Link } from "react-router-dom";

export default function Product(props) {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  const user = props.state.setuser.user;
  const route = props.state.setuser.route;

  const current_user = props.state.Setcurrentinfo.current_user;
  const all_products = props.state.Settablehistory.table_history;
  const dispatch = props.Settable_history;
  const RemoveUser_fun = props.RemoveUser;
  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const [showmodel, setshowmodel] = useState(false);
  const [data, setdata] = useState("");
  const [showmodelupdate, setshowmodelupdate] = useState(false);

  const [delete_user, setdelete_user] = useState(false);
  const [url_to_delete, seturl_to_delete] = useState("");
  const [row_id, setrow_id] = useState("");
  const [isloading, setisloading] = useState(false);

  const [category, setcategory] = useState({ value: "all", label: "All" });
  const [allcategory, setallcategory] = useState([]);
  const [subcategory, setsubcategory] = useState({
    value: "all",
    label: "All",
  });
  const [callagain, setcallagain] = useState(false);

  useEffect(() => {
    setisloading(true);
    dispatch({ type: "Set_menuitem", data: "products" });
    const fetchWorkouts = async () => {
      var url = `${route}/api/products/?user_id=${current_user.id}`;
      if (category.value != "all") {
        url = `${url}&category_id=${category.value}`;
      }

      const response = await fetch(`${url}`, {
        headers: { Authorization: `Bearer ${user.access}` },
      });
      const json = await response.json();

      if (response.ok) {
        setisloading(false);
        dispatch({ type: "Set_table_history", data: json });
      }
      if (!response.ok) {
        went_wrong_toast();
        setisloading(false);
        if (json.code === "token_not_valid") {
          RemoveUser_fun();
        }
      }
    };

    if (user) {
      fetchWorkouts();
    }
  }, [callagain]);

  useEffect(() => {
    const fetchCategory = async () => {
      const response = await fetch(
        `${route}/api/categories/?user_id=${current_user.id}`,
        {
          headers: { Authorization: `Bearer ${user.access}` },
        }
      );
      const json = await response.json();

      if (response.ok) {
        const optimize = json.map((item) => {
          return { value: item.id, label: item.name };
        });
        optimize.splice(0, 0, { value: "all", label: "All" });
        setallcategory(optimize);
      }
    };

    if (user) {
      fetchCategory();
    }
  }, []);

  const headerstyle = (column, colIndex, { sortElement }) => {
    return (
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ minHeight: "2.5rem" }}
      >
        {column.text}
        {sortElement}
      </div>
    );
  };

  const handleconfirm = (row) => {
    dispatch({ type: "Delete_table_history", data: { id: row } });
    custom_toast("Delete");
  };

  const Action = (cell, row, rowIndex, formatExtraData) => {
    return (
      <span className="action d-flex">
        <IconButton
          className="border border-danger rounded me-2"
          onClick={() => {
            setrow_id(row.id);
            seturl_to_delete(`${route}/api/products/${row.id}/`);
            setdelete_user(true);
          }}
        >
          <DeleteRoundedIcon className="m-1" color="error" fontSize="medium" />
        </IconButton>

        <IconButton
          className="me-2"
          style={{ border: "1px solid #003049", borderRadius: "5px" }}
          onClick={() => {
            setdata(row);
            setshowmodelupdate(true);
          }}
        >
          <EditOutlinedIcon
            className="m-1"
            style={{ color: "#003049" }}
            fontSize="medium"
          />
        </IconButton>

        <Link to={`/singleproductdetail/${row.id}`} target="blank">
          <IconButton
            className="p-1"
            style={{ border: "1px solid #003049", borderRadius: "5px" }}
          >
            <VisibilityIcon
              className=""
              style={{ color: "#003049" }}
              fontSize="medium"
            />
          </IconButton>
        </Link>
      </span>
    );
  };

  const loadimage = (cell, row, rowIndex, formatExtraData) => {
    return <Avatar src={cell} alt="logo" />;
  };

  const check_status = (cell, row, rowIndex, formatExtraData) => {
    if (cell) {
      if (cell.toLowerCase() === "available") {
        return <span className="text-success">{cell}</span>;
      } else if (cell.toLowerCase() === "not available") {
        return <span className="text-danger">{cell}</span>;
      }
    }
  };

  const columns = [
    {
      dataField: "id",
      text: "Id",
      hidden: true,
      headerFormatter: headerstyle,
      csvExport: false,
    },
    {
      dataField: "images[0].picture",
      text: "Pic",
      formatter: loadimage,
      headerFormatter: headerstyle,
      csvExport: false,
    },
    {
      dataField: "category_name",
      text: "Category",
      sort: true,
      headerFormatter: headerstyle,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
      headerFormatter: headerstyle,
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
      headerFormatter: headerstyle,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      headerFormatter: headerstyle,
      formatter: check_status,
    },

    {
      dataField: "action",
      text: "Action",
      formatter: Action,
      headerFormatter: headerstyle,
      csvExport: false,
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total ms-2">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "All",
        value: all_products.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const makepdf = () => {
    const body = all_products.map((item, index) => {
      return [
        index + 1,
        item.category_name,
        item.sub_category_name,
        item.name,
        item.code,
        item.barcode,
        item.quantity,
        item.price,
      ];
    });
    body.splice(0, 0, [
      "#",
      "Category",
      "Sub Category",
      "Name",
      "Code",
      "Barcode",
      "Qty.",
      "Price",
    ]);

    const documentDefinition = {
      content: [
        { text: "Products", style: "header" },
        { text: `User Name: ${current_user.username}`, style: "body" },
        {
          canvas: [
            { type: "line", x1: 0, y1: 10, x2: 760, y2: 10, lineWidth: 1 },
          ],
        },

        {
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 1,
            widths: [30, "*", "*", "*", "*", "*", "*", "*"],
            body: body,
          },
          style: "tableStyle",
        },
      ],

      defaultStyle: {
        font: "ArabicFont",
      },
      styles: {
        tableStyle: {
          width: "100%", // Set the width of the table to 100%
          marginTop: 20,
          font: "ArabicFont",
        },

        header: {
          fontSize: 22,
          bold: true,
          alignment: "center",
        },
        body: {
          fontSize: 12,
          bold: true,
          alignment: "center",
          marginBottom: 10,
        },
      },

      pageOrientation: "landscape",
    };
    return documentDefinition;
  };

  const download = () => {
    const documentDefinition = makepdf();
    pdfMake.createPdf(documentDefinition).download("Products.pdf");
  };

  const print = () => {
    const documentDefinition = makepdf();
    pdfMake.createPdf(documentDefinition).print();
  };

  const rowstyle = { height: "10px" };

  useEffect(() => {
    setcallagain(!callagain);
  }, [category, subcategory]);

  const handlecategory = (e) => {
    setcategory(e);
  };

  const handlesubcategory = (e) => {
    setsubcategory(e);
  };
  const selectStyles = {
    menu: (base) => ({
      ...base,
      zIndex: 100,
    }),
  };

  const rowClasses = (row, rowIndex) => {
    let classes = null;
    if (row.quantity === 0) {
      classes = "low_quantity";
    }

    return classes;
  };

  return (
    <div className="p-3">
      <h1 className="mb-3" style={{ fontSize: "1.8rem", fontWeight: "normal" }}>
        Products
      </h1>
      <div className="card">
        <div className="card-header bg-white d-md-flex justify-content-between">
          <h1
            className="mb-3"
            style={{ fontSize: "1.3rem", fontWeight: "normal" }}
          >
            Products List
          </h1>
          <div>
            <Link to={`/products_detail/${current_user.id}`} target="blank">
              <Button
                type="button"
                className="mb-2 me-2"
                variant="outline-primary"
              >
                <VisibilityIcon /> Show Products
              </Button>
            </Link>
            <Button
              type="button"
              className="mb-2"
              variant="outline-success"
              onClick={() => setshowmodel(!showmodel)}
            >
              <ApartmentIcon /> Add Product
            </Button>
          </div>
        </div>

        <div className="card-body pt-0">
          <ToolkitProvider
            keyField="id"
            data={all_products}
            columns={columns}
            search
            exportCSV
          >
            {(props) => (
              <div>
                <div className=" col-sm-2 d-flex mt-3">
                  <div className=" me-3 form-control selector mb-2">
                    <Select
                      options={allcategory}
                      placeholder="Categories"
                      value={category}
                      funct={handlecategory}
                    ></Select>
                  </div>
                </div>

                <div className="d-md-flex justify-content-between align-items-end mt-2">
                  <div className="mb-2">
                    <ExportCSVButton
                      {...props.csvProps}
                      className="csvbutton  border bg-secondary text-light me-2"
                    >
                      Export CSV
                    </ExportCSVButton>
                    <Button
                      type="button"
                      className="p-1 ps-3 pe-3 me-2"
                      variant="outline-primary"
                      onClick={download}
                    >
                      <PictureAsPdfIcon /> PDF
                    </Button>
                    <Button
                      type="button"
                      className="p-1 ps-3 pe-3"
                      variant="outline-success"
                      onClick={print}
                    >
                      <PrintIcon /> Print
                    </Button>
                  </div>
                  <SearchBar {...props.searchProps} />
                </div>

                {isloading && (
                  <div className="text-center">
                    <Spinner animation="border" variant="primary" />
                  </div>
                )}

                <hr />
                <BootstrapTable
                  {...props.baseProps}
                  pagination={paginationFactory(options)}
                  rowStyle={rowstyle}
                  striped
                  bootstrap4
                  condensed
                  rowClasses={rowClasses}
                  wrapperClasses="table-responsive"
                />
              </div>
            )}
          </ToolkitProvider>
        </div>
      </div>

      {showmodel && (
        <Productform
          show={showmodel}
          onHide={() => setshowmodel(false)}
          user={user}
          route={route}
          callback={dispatch}
          current_user={current_user}
          check="false"
          RemoveUser_fun={RemoveUser_fun}
        />
      )}
      {showmodelupdate && (
        <Productupdate
          show={showmodelupdate}
          onHide={() => setshowmodelupdate(false)}
          data={data}
          user={user}
          route={route}
          fun={custom_toast}
          callback={dispatch}
          current_user={current_user}
          RemoveUser_fun={RemoveUser_fun}
        />
      )}
      {delete_user && (
        <Alert_before_delete
          show={delete_user}
          onHide={() => setdelete_user(false)}
          url={url_to_delete}
          dis_fun={handleconfirm}
          row_id={row_id}
        />
      )}
      <ToastContainer autoClose={1000} hideProgressBar={true} theme="dark" />
    </div>
  );
}
