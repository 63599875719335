import { connect } from "react-redux";
import Layout from "../Pages/home";
import { setuser } from "../Service/Actions/action";

const dispatchpart=dispatch=>({
  
})

const getpart=state=>({
    state

})

export default connect(getpart,dispatchpart)(Layout)