import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "./sale.css";
import Button from "react-bootstrap/Button";
import { IconButton } from "@material-ui/core";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import Red_toast from "../alerts/red_toast";
import ToolkitProvider, {
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Select from "react-select";
import PrintRoundedIcon from "@material-ui/icons/PrintRounded";
import SaveIcon from "@material-ui/icons/Save";
import TextField from "@mui/material/TextField";
import InputGroup from "react-bootstrap/InputGroup";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { ToastContainer } from "react-toastify";
import went_wrong_toast from "../alerts/went_wrong_toast";
import Saleform from "./saleform";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import AddIcon from "@material-ui/icons/Add";
import Citymodel from "./citymodel";

export default function Sale_Edit(props) {
  const navigate = useNavigate();
  const user = props.state.setuser.user;
  const route = props.state.setuser.route;

  const current_user = props.state.Setcurrentinfo.current_user;
  const check_status = props.state.Settablehistory.table_history;
  const dispatch = props.Settable_history;
  const id_data = JSON.parse(localStorage.getItem("data"));
  const table_data = props.state.Setproducthistory.product_history;
  const settable_data = props.Setproduct_history;
  const [all_product_option, setall_product_option] = useState([]);
  const [all_product, setall_product] = useState([]);
  const [placeholder, setplaceholder] = useState("Search Products");
  const RemoveUser_fun = props.RemoveUser;
  const [text, settext] = useState("");
  const [url, seturl] = useState("");

  const [call_products_again, setcall_products_again] = useState(false);
  var curr = new Date();
  var curdate = curr.toISOString().substring(0, 10);

  const [payment_date, setpayment_date] = useState(curdate);
  const [payment_amount, setpayment_amount] = useState("");
  const [payment_notes, setpayment_notes] = useState("");
  const [payment_data, setpayment_data] = useState([]);
  const [payment_id, setpayment_id] = useState("");
  const [payment_counter, setpayment_counter] = useState(1);

  const [invoice, setinvoice] = useState("");
  const [date, setdate] = useState(curdate);
  const [to_date, setto_date] = useState(curdate);
  const [from_date, setfrom_date] = useState(curdate);

  const [counter, setcounter] = useState(1);

  const [customer, setcustomer] = useState("");
  const [contact, setcontact] = useState("");
  const [cnic, setcnic] = useState("");
  const [address, setaddress] = useState("");

  const [total, settotal] = useState(0);
  const [amount_received, setamount_received] = useState(0);
  const [notes, setnotes] = useState("");

  const [showmodel, setshowmodel] = useState(false);
  const [notemodel, setnotemodel] = useState(false);
  const [isloading, setisloading] = useState(false);
  const [remaining, setremaining] = useState(0);
  const [print, setprint] = useState(false);

  const [product, setproduct] = useState("");
  const [data, setdata] = useState("");
  const [city, setcity] = useState("");
  const [all_city, setall_city] = useState([]);
  const [showcity_model, setshowcity_model] = useState(false);
  const [call_city_again, setcall_city_again] = useState(false);
  const [area, setarea] = useState("");
  const [payment, setpayment] = useState(0);

  useEffect(() => {
    dispatch({ type: "Set_menuitem", data: "sale_history" });
  }, []);

  useEffect(() => {
    const fetchcity = async () => {
      const response = await fetch(
        `${route}/api/city/?user_id=${current_user.id}`,
        {
          headers: { Authorization: `Bearer ${user.access}` },
        }
      );
      const json = await response.json();

      if (response.ok) {
        const optimize = json.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
        setall_city(optimize);
      }
      if (!response.ok) {
        went_wrong_toast();
        if (json.code === "token_not_valid") {
          RemoveUser_fun();
        }
      }
    };

    fetchcity();
  }, [call_city_again]);

  useEffect(() => {
    const fetchhistory = async () => {
      settable_data({ type: "Set_product_history", data: [] });
      const response = await fetch(`${route}/api/bookings/${id_data.id}/`, {
        headers: { Authorization: `Bearer ${user.access}` },
      });

      const json = await response.json();

      if (response.ok) {
        var count = 0;

        setdate(json.date);
        setinvoice(json.booking_number);
        setcustomer(json.customer);
        setfrom_date(json.from_date);
        setto_date(json.to_date);
        setnotes(json.remarks);
        setcnic(json.cnic);
        setcontact(json.contact);
        setaddress(json.address);
        settotal(json.total);
        if (json.city) {
          setcity({ value: json.city, label: json.city_name });
        }

        setarea(json.area);
        setpayment(json.amount_received);
        setremaining(json.total - json.amount_received);

        const optimize_payent = json.payments.map((item) => {
          count += 1;
          item["payment_id"] = count;
          return item;
        });
        setpayment_counter(count);
        count = 0;
        setpayment_data(optimize_payent);

        const filter_data = json.details.map((item) => {
          count += 1;
          return {
            product: item.product,
            prod_id: count,
            name: item.product_name,

            quantity: 1,
            price: item.amount,

            total: item.amount,
          };
        });
        setcounter(count + 1);

        settable_data({ type: "Set_product_history", data: filter_data });
      } else {
        went_wrong_toast();
        if (json.code === "token_not_valid") {
          RemoveUser_fun();
        }
      }
    };

    if (user) {
      fetchhistory();
    }
  }, []);

  useEffect(() => {
    if (invoice && table_data.length > 0) {
      setprint(true);
    } else {
      setprint(false);
    }
  }, [invoice, table_data]);

  useEffect(() => {
    settable_data({ type: "Set_product_history", data: [] });
    const fetchProducts = async () => {
      const response = await fetch(
        `${route}/api/products/?user_id=${current_user.id}`,
        {
          headers: { Authorization: `Bearer ${user.access}` },
        }
      );
      const json = await response.json();

      if (response.ok) {
        setall_product(json);
        const pro = json.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
        setall_product_option(pro);
      }
      if (!response.ok) {
        went_wrong_toast();
      }
    };

    if (user) {
      fetchProducts();
    }
  }, [check_status, call_products_again]);

  useEffect(() => {
    var total_total = 0;
    table_data.forEach((item) => {
      total_total += Number(item.total);
    });

    settotal(total_total);
    setremaining(total_total - payment);
  }, [table_data]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("reach");
    if (!isloading) {
      const data_details = table_data.map((item) => {
        return {
          product: item.product,
          quantity: item.quantity,
          amount: item.total,
        };
      });

      const payment_details = payment_data.map((item) => {
        delete item["payment_id"];
        return item;
      });
      setisloading(true);

      const formdata = {};
      formdata["remarks"] = notes;
      formdata["date"] = date;
      formdata["booking_number"] = invoice;
      formdata["from_date"] = from_date;
      formdata["to_date"] = to_date;
      formdata["customer"] = customer;
      formdata["contact"] = contact;
      formdata["cnic"] = cnic;
      formdata["address"] = address;
      formdata["total"] = total;
      formdata["payments"] = payment_details;
      formdata["details"] = data_details;
      if (city) {
        formdata["city"] = city.value;
      } else {
        formdata["city"] = "";
      }

      formdata["area"] = area;
      formdata["amount_received"] = payment;

      const response = await fetch(`${route}/api/bookings/${id_data.id}/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.access}`,
        },
        body: JSON.stringify(formdata),
      });
      const json = await response.json();

      if (!response.ok) {
        setisloading(false);
        settable_data({ type: "Set_product_history", data: [] });
        Red_toast(json.error);
      }

      if (response.ok) {
        setisloading(false);

        settable_data({ type: "Set_product_history", data: [] });
        navigate("/sale_history");
      }
    }
  };

  const handlePrint = async () => {
    if (!isloading) {
      const data_details = table_data.map((item) => {
        return {
          product: item.product,
          quantity: item.quantity,
          amount: item.total,
        };
      });

      const payment_details = payment_data.map((item) => {
        delete item["payment_id"];
        return item;
      });

      const formdata = {};

      formdata["remarks"] = notes;
      formdata["date"] = date;
      formdata["booking_number"] = invoice;
      formdata["from_date"] = from_date;
      formdata["to_date"] = to_date;
      formdata["customer"] = customer;
      formdata["contact"] = contact;
      formdata["cnic"] = cnic;
      formdata["address"] = address;
      if (city) {
        formdata["city"] = city.value;
      } else {
        formdata["city"] = "";
      }
      formdata["area"] = area;
      formdata["total"] = total;
      formdata["payments"] = payment_details;
      formdata["amount_received"] = payment;
      formdata["details"] = data_details;

      const response = await fetch(`${route}/api/bookings/${id_data.id}/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.access}`,
        },
        body: JSON.stringify(formdata),
      });
      const json = await response.json();

      if (!response.ok) {
        settable_data({ type: "Set_product_history", data: [] });
        Red_toast(json.error);
      }

      if (response.ok) {
        localStorage.setItem("data", JSON.stringify(json));
        settable_data({ type: "Set_product_history", data: [] });

        window.open("/invoice/sales", "_blank");
        navigate("/sale_history");
      }
    }
  };

  const linkFollow = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div className="action text-center">
        <IconButton
          onClick={() => {
            settable_data({ type: "Delete_product_history", data: row });
          }}
        >
          <DeleteRoundedIcon color="error" fontSize="medium" />
        </IconButton>
      </div>
    );
  };

  const headerstyle = (column, colIndex, { sortElement }) => {
    return (
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ minHeight: "2.5rem" }}
      >
        {column.text}
        {sortElement}
      </div>
    );
  };

  const name_column_formater = (cell, row) => {
    return <div style={{ width: "18vw" }}>{cell}</div>;
  };

  const columns = [
    {
      dataField: "row_number",
      text: "#",
      headerFormatter: headerstyle,
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      editable: false,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
      headerFormatter: headerstyle,
      formatter: name_column_formater,
      editable: false,
    },
    { dataField: "quantity", text: "Qty", headerFormatter: headerstyle },
    { dataField: "price", text: "Price", headerFormatter: headerstyle },
    {
      dataField: "Edit",
      text: "Action",
      formatter: linkFollow,
      headerFormatter: headerstyle,
      editable: false,
    },
  ];

  const linkFollow_payment = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div className="action text-center">
        <IconButton
          onClick={() => {
            const get_payment_data = payment_data.filter((item) => {
              return item.payment_id !== row.payment_id;
            });
            setpayment_data(get_payment_data);
          }}
        >
          <DeleteRoundedIcon className="me-2" color="error" fontSize="medium" />
        </IconButton>
        <IconButton
          onClick={() => {
            setpayment_date(row.date);
            setpayment_amount(row.amount);
            setpayment_notes(row.remarks);
            setpayment_id(row.payment_id);
          }}
        >
          <EditOutlinedIcon color="primary" fontSize="medium" />
        </IconButton>
      </div>
    );
  };

  const column_payment = [
    {
      dataField: "row_number",
      text: "#",
      headerFormatter: headerstyle,
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      editable: false,
    },
    {
      dataField: "date",
      text: "Date",
      sort: true,
      headerFormatter: headerstyle,
      editable: false,
    },
    { dataField: "amount", text: "Amount", headerFormatter: headerstyle },
    { dataField: "remarks", text: "Remarks", headerFormatter: headerstyle },
    {
      dataField: "Edit",
      text: "Action",
      formatter: linkFollow_payment,
      headerFormatter: headerstyle,
      editable: false,
    },
  ];

  useEffect(() => {
    var total_receive = 0;
    payment_data.map((item) => {
      total_receive += Number(item.amount);
    });
    setpayment(total_receive);
    setremaining(total - total_receive);
  }, [payment_data]);

  const Add_payment = (e) => {
    e.preventDefault();
    if (payment_amount) {
      if (payment_id) {
        const is_newdata = payment_data.map((item) => {
          if (item.payment_id === payment_id) {
            return {
              id: item.id,
              payment_id: item.payment_id,
              date: payment_date,
              amount: payment_amount,
              remarks: payment_notes,
            };
          } else {
            return item;
          }
        });
        setpayment_data(is_newdata);
        setpayment_id("");
        setpayment_amount("");
        setpayment_notes("");
        setpayment_date(curdate);
      } else {
        setpayment_data((payment_data) => [
          ...payment_data,
          {
            payment_id: payment_counter + 1,
            date: payment_date,
            amount: payment_amount,
            remarks: payment_notes,
          },
        ]);
        setpayment_amount("");
        setpayment_notes("");
        setpayment_date(curdate);
        setpayment_counter(payment_counter + 1);
      }
    }
  };

  const handleproduct_selection = (selected_option) => {
    const item_present = table_data.filter((item) => {
      return item.product === selected_option.value;
    });

    if (item_present.length === 0) {
      var filter_data = all_product.filter((item) => {
        if (item.id === selected_option.value) {
          return item;
        }
      });

      filter_data = filter_data.map((item) => {
        return {
          product: item.id,
          prod_id: counter,
          name: item.name,
          quantity: 1,
          price: 0,
          total: 0,
        };
      });
      settable_data({ type: "Create_product_history", data: filter_data[0] });
      setcounter(counter + 1);
    } else {
      Red_toast(`Product Already  Added !`);
    }
  };

  const handlecellchange = (oldValue, newValue, row, column) => {
    if (column.dataField === "price") {
      var new_data = table_data.map((item) => {
        return item.product !== row.product
          ? item
          : {
              product: item.product,
              prod_id: item.prod_id,
              name: item.name,
              quantity: item.quantity,
              price: newValue,
              total: item.quantity * newValue,
            };
      });

      settable_data({ type: "Set_product_history", data: new_data });
    } else if (column.dataField === "quantity") {
      var new_data = table_data.map((item) => {
        return item.product !== row.product
          ? item
          : {
              product: item.product,
              prod_id: item.prod_id,
              name: item.name,
              quantity: newValue,
              price: item.price,
              total: item.price * newValue,
            };
      });

      settable_data({ type: "Set_product_history", data: new_data });
    }
  };

  const selectStyles = {
    menu: (base) => ({
      ...base,
      zIndex: 100,
    }),
  };

  return (
    <div className="p-3">
      <h1 className="mb-3" style={{ fontSize: "1.8rem", fontWeight: "normal" }}>
        Booking Edit
      </h1>
      <ToolkitProvider
        keyField="prod_id"
        data={table_data}
        columns={columns}
        search
        exportCSV
      >
        {(props) => (
          <div className="card">
            <form onSubmit={handleSubmit}>
              <div className="card-header  d-flex justify-content-end">
                <Button type="submit" variant="outline-primary">
                  <SaveIcon /> Save
                </Button>

                {print ? (
                  <Button
                    className="ms-2"
                    variant="outline-success"
                    onClick={handlePrint}
                  >
                    <PrintRoundedIcon /> Print
                  </Button>
                ) : (
                  <Button className="ms-2" variant="outline-success" disabled>
                    <PrintRoundedIcon /> Print
                  </Button>
                )}
              </div>

              <Tabs
                defaultActiveKey="Booking"
                transition={false}
                id="noanim-tab-example"
                className="mb-3"
              >
                <Tab eventKey="Booking" title="Booking">
                  <div className="card-body ">
                    <div className="d-md-flex align-items-start mt-1">
                      <div className="col-sm-7  pe-md-5">
                        <div className="mt-2 d-flex justify-content-center align-items-center">
                          <div className="col-md-8 d-flex align-items-center">
                            <div className="col-md-8">
                              <InputGroup>
                                <Select
                                  className="form-control selector"
                                  placeholder={placeholder}
                                  options={all_product_option}
                                  value={product}
                                  onChange={handleproduct_selection}
                                ></Select>
                                <IconButton
                                  className="p-0 ps-1 pe-1"
                                  style={{
                                    backgroundColor: "#0d6efd",
                                    borderRadius: "0",
                                  }}
                                  onClick={() => {
                                    settext("Products");
                                    seturl(
                                      `${route}/api/products/?user_id=${current_user.id}`
                                    );
                                    setshowmodel(!showmodel);
                                    setdata(all_product);
                                  }}
                                >
                                  <VisibilityIcon
                                    style={{
                                      color: "white",
                                      height: "fit-content",
                                    }}
                                    fontSize="medium"
                                  />
                                </IconButton>
                              </InputGroup>
                            </div>
                          </div>
                        </div>

                        <hr />

                        <div
                          style={{ height: "60vh", border: "1px solid gray" }}
                        >
                          <div style={{ height: "50vh", overflow: "auto" }}>
                            <BootstrapTable
                              {...props.baseProps}
                              bordered={false}
                              bootstrap4
                              condensed
                              cellEdit={cellEditFactory({
                                mode: "click",
                                blurToSave: true,
                                afterSaveCell: handlecellchange,
                              })}
                              rowClasses="custom_row_class"
                              wrapperClasses="table-responsive"
                            />
                          </div>
                          <div className="d-flex justify-content-between mt-3">
                            <div className="col-md-3 ms-2 me-2">
                              <TextField
                                className="form-control"
                                id="outlined-basic"
                                label="Total"
                                value={total}
                                onChange={(e) => {
                                  settotal(e.target.value);
                                }}
                                size="small"
                              />
                            </div>
                            <div className="col-md-3 ms-2 me-2">
                              <TextField
                                className="form-control"
                                id="outlined-basic"
                                label="Amount Received"
                                value={payment}
                                onChange={(e) => {
                                  setpayment(e.target.value);
                                  setremaining(
                                    total - amount_received - e.target.value
                                  );
                                }}
                                size="small"
                              />
                            </div>

                            <div className="col-md-3 me-2">
                              <TextField
                                className="form-control "
                                id="outlined-basic"
                                label="Remaing"
                                value={remaining}
                                size="small"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-5 mt-3">
                        <div>
                          <div className="d-flex justify-content-between">
                            <div className="col-6 me-2">
                              <TextField
                                className="form-control   mb-4"
                                label="Booking No."
                                value={invoice}
                                size="small"
                                required
                              />
                            </div>

                            <div className="col-6">
                              <TextField
                                type="date"
                                className="form-control   mb-4"
                                label="Date"
                                InputLabelProps={{ shrink: true }}
                                value={date}
                                onChange={(e) => {
                                  setdate(e.target.value);
                                }}
                                size="small"
                              />
                            </div>
                          </div>

                          <div className="d-flex justify-content-between">
                            <div className="col-6 me-2">
                              <TextField
                                type="date"
                                className="form-control   mb-4"
                                InputLabelProps={{ shrink: true }}
                                label="From"
                                value={from_date}
                                onChange={(e) => {
                                  setfrom_date(e.target.value);
                                }}
                                size="small"
                                required
                              />
                            </div>

                            <div className="col-6">
                              <TextField
                                type="date"
                                className="form-control   mb-4"
                                label="To"
                                InputLabelProps={{ shrink: true }}
                                value={to_date}
                                onChange={(e) => {
                                  setto_date(e.target.value);
                                }}
                                size="small"
                              />
                            </div>
                          </div>
                        </div>

                        <div>
                          <div className="d-flex justify-content-between">
                            <div className="col-6 me-2">
                              <TextField
                                className="form-control   mb-4"
                                label="Customer"
                                value={customer}
                                onChange={(e) => {
                                  setcustomer(e.target.value);
                                }}
                                size="small"
                                required
                              />{" "}
                            </div>

                            <div className="col-6">
                              <TextField
                                className="form-control   mb-4"
                                label="Conact"
                                value={contact}
                                onChange={(e) => {
                                  setcontact(e.target.value);
                                }}
                                size="small"
                              />
                            </div>
                          </div>

                          <div className="d-flex justify-content-between">
                            <div className="col-6 me-2">
                              <TextField
                                className="form-control   mb-4"
                                label="CNIC"
                                value={cnic}
                                onChange={(e) => {
                                  setcnic(e.target.value);
                                }}
                                size="small"
                              />
                            </div>

                            <div className="col-6">
                              <TextField
                                multiline
                                className="form-control   mb-4"
                                label="Address"
                                value={address}
                                onChange={(e) => {
                                  setaddress(e.target.value);
                                }}
                                size="small"
                              />
                            </div>
                          </div>

                          <div className="d-flex justify-content-between">
                            <div className="col-6 me-2">
                              <InputGroup>
                                <Select
                                  className={
                                    city
                                      ? "form-control selector saleman"
                                      : "form-control selector"
                                  }
                                  styles={selectStyles}
                                  options={all_city}
                                  placeholder="City"
                                  value={city}
                                  onChange={(e) => {
                                    setcity(e);
                                  }}
                                ></Select>

                                <IconButton
                                  className="p-0 ps-1 pe-1"
                                  style={{
                                    backgroundColor: "#0d6efd",
                                    borderRadius: "0",
                                  }}
                                  onClick={() => {
                                    setshowcity_model(!showcity_model);
                                  }}
                                >
                                  <AddIcon
                                    style={{
                                      color: "white",
                                      height: "fit-content",
                                    }}
                                    fontSize="medium"
                                  />
                                </IconButton>
                              </InputGroup>
                            </div>

                            <div className="col-6">
                              <TextField
                                className="form-control   mb-4"
                                label="Area"
                                value={area}
                                onChange={(e) => {
                                  setarea(e.target.value);
                                }}
                                size="small"
                              />
                            </div>
                          </div>

                          <div className="row ps-2 pe-2">
                            <TextField
                              multiline
                              rows={3}
                              className="form-control ms-1  mb-4"
                              id="outlined-basic"
                              label="Notes"
                              value={notes}
                              onChange={(e) => {
                                setnotes(e.target.value);
                              }}
                              size="small"
                            />
                          </div>
                        </div>

                        <div></div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="Payment" title="Payments">
                  <div className="card-body ">
                    <div className="d-md-flex align-items-start mt-1">
                      <div className="col-sm-7  pe-md-5">
                        <div
                          style={{ height: "70vh", border: "1px solid gray" }}
                        >
                          <div style={{ height: "70vh", overflow: "auto" }}>
                            <BootstrapTable
                              keyField="id"
                              data={payment_data}
                              columns={column_payment}
                              bordered={false}
                              bootstrap4
                              condensed
                              rowClasses="custom_row_class"
                              wrapperClasses="table-responsive"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-5 ">
                        <div>
                          <div className="d-flex justify-content-between">
                            <div className="col-6 me-2">
                              <TextField
                                type="date"
                                className="form-control   mb-4"
                                label="Date"
                                InputLabelProps={{ shrink: true }}
                                value={payment_date}
                                onChange={(e) => {
                                  setpayment_date(e.target.value);
                                }}
                                size="small"
                              />
                            </div>
                            <div className="col-6 ">
                              <TextField
                                className="form-control   mb-4"
                                label="Payment"
                                value={payment_amount}
                                onChange={(e) => {
                                  setpayment_amount(e.target.value);
                                }}
                                size="small"
                              />
                            </div>
                          </div>
                        </div>

                        <div>
                          <div className="row ps-2 pe-2">
                            <TextField
                              multiline
                              rows={3}
                              className="form-control ms-1  mb-4"
                              id="outlined-basic"
                              label="Notes"
                              value={payment_notes}
                              onChange={(e) => {
                                setpayment_notes(e.target.value);
                              }}
                              size="small"
                            />
                          </div>
                          <div className="text-end">
                            <Button
                              variant="outline-primary"
                              onClick={Add_payment}
                            >
                              <SaveIcon /> Add
                            </Button>
                          </div>
                        </div>

                        <div></div>
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>

              <ToastContainer
                autoClose={1000}
                hideProgressBar={true}
                theme="dark"
              />
            </form>
          </div>
        )}
      </ToolkitProvider>

      {showmodel && (
        <Saleform
          show={showmodel}
          onHide={() => setshowmodel(false)}
          user={user}
          route1={url}
          callback={settable_data}
          text={text}
          counter={counter}
          setcounter={setcounter}
          table_data={table_data}
          current_user={current_user}
          route={route}
          data_={data}
        />
      )}

      {showcity_model && (
        <Citymodel
          show={showcity_model}
          onHide={() => setshowcity_model(false)}
          user={user}
          current_user={current_user}
          route={route}
          callagain={() => setcall_city_again(!call_city_again)}
        />
      )}
    </div>
  );
}
