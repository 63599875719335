import React,{useState,useRef} from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './categories.css'
import { ToastContainer } from 'react-toastify';
import TextField from '@mui/material/TextField';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { Avatar } from '@material-ui/core';
import went_wrong_toast from '../alerts/went_wrong_toast';
import success_toast from '../alerts/success_toast';
import Save_button from '../buttons/save_button';

function Categoriesform({show,onHide,user,route,callback,current_user}) {
    
    
    const [name,setname] = useState('')
    
    const [isloading , setisloading] = useState(false)


    const handleSubmit=async(e)=>{
        e.preventDefault()
        if (current_user){
          
          setisloading(true)
        const formData = new FormData();
        
        formData.append('name', name);
        formData.append('user', current_user.id);
     
        
       
       

          const response= await fetch(`${route}/api/categories/`,{
            method: "POST",
            headers: { 
              Authorization : `Bearer ${user.access}`
             },
            body: formData,
        })
        const json=await response.json();
        
        if (!response.ok){
          setisloading(false)
          went_wrong_toast()
      }

      if (response.ok){
        callback({type:'Create_table_history',data: json})
        setisloading(false)
        success_toast()
          setname('')

          
          

        }}

        
    }
    


    return (
      <Modal
        show={show}
        onHide={onHide}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        style={{zoom:'0.85'}}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className='d-flex align-items-md-center'>
            <FontAwesomeIcon className='me-2' icon={faUserPlus}/>  Add Category
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        
          <form onSubmit={handleSubmit}>
          
          

            <div className='col-md-12'>
                <TextField className='form-control   mb-3' id="outlined-basic" label="Name"  value={name} onChange={(e)=>{setname(e.target.value)}} size='small' required/>
      
            </div>

            
            

          
          
              
                 
              <hr></hr>
              <div className=' d-flex flex-row-reverse mt-2 me-2'>
              <Save_button isloading={isloading}/>
              </div>
        </form>
        
        </Modal.Body>
      </Modal>
    );
  }


export default Categoriesform


    