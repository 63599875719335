import React, { useState , useEffect } from 'react'
import Widget from './widget'
import './dashboard.css'
import ApartmentIcon from '@material-ui/icons/Apartment';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faTag } from "@fortawesome/free-solid-svg-icons";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import custom_toast from '../alerts/custom_toast';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { Chart } from 'react-google-charts';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Userupdate from '../users/userupdateform'
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import VisibilityIcon from '@material-ui/icons/Visibility';

function Dashboard(props) {
  const [date , setdate] = useState(new Date().toISOString().substring(0,10))
  const user = props.state.setuser.user
  const route = props.state.setuser.route
  const [showmodelupdate,setshowmodelupdate]=useState(false)
  const current_user = props.state.Setcurrentinfo.current_user
  const dispatch = props.Settable_history  
  const [data_1, setdata_1] =useState([])
  const [data_2, setdata_2] =useState([])

  

const [data_weekly , setdata_weekly] = useState([])

  
  useEffect(()=>{
    dispatch({type:'Set_menuitem', data:'dashboard'})
  },[])

  useEffect(() => {
    
    const fetchWorkouts = async () => {
      const response = await fetch(`${route}/api/starting-bookings/?user_id=${current_user.id}`, {
        headers: { Authorization: `Bearer ${user.access}` },
      });
      
      const json = await response.json();

      if (response.ok) {
        setdata_1(json)  
      }
    };

    const fetchWorkouts_2 = async () => {
      const response = await fetch(`${route}/api/closing-bookings/?user_id=${current_user.id}`, {
        headers: { Authorization: `Bearer ${user.access}` },
      });
      
      const json = await response.json();

      if (response.ok) {
        setdata_2(json)  
      }
    };

   

    fetchWorkouts()
    fetchWorkouts_2()
   

  },[])


  

 

  
  const options = {
    annotations: {
      textStyle: {
        color: 'black',
      }},
    chartArea: { width: '90%' },
    series: {
      0: { targetAxisIndex: 0, type: 'bars' },
      1: { targetAxisIndex: 1, type: 'bars' },
    },
    hAxis: {
      gridlines: {
        count: 5, // Set the count to the desired number of vertical gridlines
      },
    },
    
  
  };

  const headerstyle = (column , colIndex,  {sortElement})=>{
    return(
      <div className='d-flex justify-content-between align-items-center' style={{minHeight:'2.5rem'}}>
        {column.text}
      </div>
    )
  }

  const columns = [
       
    
    { dataField: 'booking_number', text: 'Booing ID',sort: true,headerFormatter:headerstyle},
    { dataField: 'from_date', text: 'From Date',sort: true,headerFormatter:headerstyle},
    { dataField: 'customer', text: 'Customer',sort: true,headerFormatter:headerstyle},
    { dataField: 'contact', text: 'Contact',sort: true,headerFormatter:headerstyle},


  ]  
  const columns_2 = [
       
    
    { dataField: 'booking_number', text: 'Booing ID',sort: true,headerFormatter:headerstyle},
    { dataField: 'to_date', text: 'To Date',sort: true,headerFormatter:headerstyle},
    { dataField: 'customer', text: 'Customer',sort: true,headerFormatter:headerstyle},
    { dataField: 'contact', text: 'Contact',sort: true,headerFormatter:headerstyle},


  ]  

  
  



  return (
    <div className='p-3'>
        <h1 className='mb-3' style={{fontSize:'1.8rem', fontWeight:'normal'}}>Dashboard</h1>
        
        <div className='row  p-2 pb-0'>

        <div className='col-6 col-sm-2 mb-2'>
        <Widget
        name='Profile'
        icon_widget={<AccountCircleIcon fontSize='inherit'/>}
        link='#'
        color='#17a2b8'
        text='ok'
        state={showmodelupdate}
        funct={setshowmodelupdate}
        />
        </div>

          
        <div className='col-6 col-sm-2  mb-2'>
        <Widget
        name='Categories'
        icon_widget={<ApartmentIcon fontSize='inherit'/>}
        link='/categories'
        color='#28a745'
        
        /></div>

      <div className='col-6 col-sm-2  mb-2'>
        <Widget
        name='Products'
        icon_widget={<FontAwesomeIcon  icon={faTag} size="inherit" />}
        link='/products'
        color='royalblue'
        
        /></div>
        
        <div className='col-6 col-sm-2 mb-2'>
        <Widget
        name='Booking'
        icon_widget={<LocalAtmIcon fontSize='inherit'/>}
        link='/sale'
        color='#ffc107'
        
        /></div>

        <div className='col-6 col-sm-2 mb-2'>
        <Widget
        name="Booking History"
        icon_widget={<ReceiptIcon fontSize='inherit'/>}
        link='/sale_history'
        color='#17a2b8'
        />
        </div>

        <div className='col-6  col-sm-2  mb-2'>
        <Widget
        name="Reports"
        icon_widget={<ReceiptIcon fontSize='inherit'/>}
        link='/sale_report'
        color='royalblue'
        />
        </div>
        
        

        
        </div>

        <div className='col-sm-12  d-sm-flex justify-content-between'>
        <div className='col-sm-6  mt-4 pe-sm-3'>
          <div className='card'>
          <div className=' card-header bg-white'> 
          Incoming Bookings
          </div>
          <div className='card-body p-0' style={{height:'16rem', overflow:'auto'}}>
            <BootstrapTable
            keyField="id"
            data={ data_1 }
            columns={ columns }                
            bordered={false}
            bootstrap4
            condensed
            wrapperClasses="table-responsive"
            />
          </div>
          </div>
        
        </div>
        <div className='col-sm-6 mt-4 ps-2  card'>
          <div className=' card-header bg-white'> 
          Expire Bookings
          </div>
          <div className='card-body p-0' style={{height:'16rem', overflow:'auto'}}>
            <BootstrapTable
            keyField="id"
            data={ data_2 }
            columns={ columns_2 }                
            bordered={false}
            bootstrap4
            condensed
            wrapperClasses="table-responsive"
            />
          </div>
        
        </div>
        </div>
        
       

      
        
      
        {showmodelupdate && <Userupdate
          show={showmodelupdate}
          onHide={()=>setshowmodelupdate(false)}
          data={current_user}
          user={user}
          route={route}
          fun={custom_toast}
          callback={dispatch}
        /> }
    </div>
  )
}

export default Dashboard
