import React,{useState,useEffect} from 'react'
import './user.css'
import { IconButton, Avatar } from '@material-ui/core';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search , CSVExport } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Userform from './userform'
import Userupdate from './userupdateform';
import Button from 'react-bootstrap/Button';
import Alert_before_delete from '../../Container/alertContainer'
import { ToastContainer } from 'react-toastify';
import custom_toast from '../alerts/custom_toast'
import went_wrong_toast from '../alerts/went_wrong_toast'
import Spinner from 'react-bootstrap/Spinner'
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import PrintIcon from '@material-ui/icons/Print';



function User(props) {
  
    
    
    const user = props.state.setuser.user
    const route = props.state.setuser.route
    const current_user = props.state.Setcurrentinfo.current_user
    const all_users = props.state.Settablehistory.table_history
    const dispatch = props.Settable_history
    const { SearchBar } = Search;
    const { ExportCSVButton } = CSVExport;
    const [showmodel , setshowmodel] = useState(false)
    const [data,setdata] = useState('')
    const [showmodelupdate,setshowmodelupdate]=useState(false)
    const [delete_user , setdelete_user] = useState(false)
    const [url_to_delete , seturl_to_delete] = useState('')
    const [row_id,setrow_id] = useState('')
    const [isloading , setisloading] = useState(false)
    
    

    useEffect(() => {
      setisloading(true)
      dispatch({type:"Set_menuitem", data:'user'})
      const fetchWorkouts = async () => {
        const response = await fetch(`${route}/api/users/?user_id=${current_user.id}`, {
          headers: { Authorization: `Bearer ${user.access}` },
          
        });

        const json = await response.json();
  
        if (response.ok) {
          
          setisloading(false)
          dispatch({type:"Set_table_history", data:json})
          
        }
        if (!response.ok){
          went_wrong_toast()
          setisloading(false)
        }
      };
  
      if (current_user) {
        fetchWorkouts();
        
      }
      
    }, [current_user]);

    const headerstyle = (column , colIndex,  {sortElement})=>{
      return(
        <div className='d-flex justify-content-between align-items-center' style={{minHeight:'2.5rem'}}>
          {column.text}
          {sortElement}
        </div>
      )
    }

    const handleconfirm=(row)=>{
      dispatch({type:'Delete_table_history',data: {id:row}})
      custom_toast('Delete')
    }


    const linkFollow = (cell, row, rowIndex, formatExtraData) => {
        return (
            <span className='action d-flex'  >
                {current_user && <>{current_user.id===1 && <IconButton className='me-2 border border-danger rounded' onClick={()=>{
                  setrow_id(row.id)
                  seturl_to_delete(`${route}/api/users/${row.id}/`)
                  setdelete_user(true)
                  }}>
                    <DeleteRoundedIcon className='m-1'  color='error' fontSize="medium"/>
                </IconButton>}</>}

                <IconButton style={{border:'1px solid #003049' , borderRadius:'5px'}} onClick={()=>{
                  setdata(row)
                  setshowmodelupdate(true)

                  }}>
                <EditOutlinedIcon className='m-1'  style={{color:'#003049'}} fontSize="medium"/>
                </IconButton>
        </span>
        );
      };
     
    

    const loadimage = (cell, row, rowIndex, formatExtraData) =>{
      
      return(
        <div className='d-flex justify-content-center'  >
          <Avatar src={cell} alt='logo'/>
        </div>
      )
  }
    

    const columns = [
      { dataField: 'id', text: 'Id',hidden: true,headerFormatter:headerstyle},
        { dataField: 'profile.picture', text: 'logo',sort: true, formatter:loadimage, headerFormatter:headerstyle},
        { dataField: 'username', text: 'Name',sort: true, headerFormatter:headerstyle},
        { dataField: 'profile.phone_number', text: 'Phone No.',sort: true, headerFormatter:headerstyle},  
        { dataField: 'profile.address', text: 'Address',sort: true, headerFormatter:headerstyle},  
        {
            dataField: "action",
            text: 'Action',
            formatter: linkFollow,
            headerFormatter:headerstyle
          }
      ];

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total ms-2">
            Showing { from } to { to } of { size } Results
        </span>
    );

    const options = {
        paginationSize: 4,
        pageStartIndex: 1,
        firstPageText: 'First',
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '10', value: 10
        }, {
            text: '20', value: 20
        }, {
            text: 'All', value: all_users.length
        }] // A numeric array is also available. the purpose of above example is custom the text
    };

    function reverseText(text) {
      return text.split(' ').reverse().join(' ');
    }
  

    
    const makepdf=()=>{
      const body=all_users.map((item,index)=>{
        
              return [{text:index+1},{text:item.username}, {text:item.profile.phone_number}, {text:item.profile.address}]
      })
      body.splice(0,0,['#','Name', 'Phone No.','Address'])
      body.push(['3',reverseText('قائمة المستخدمين'), '0872166','Address'])
      body.push( ['4',reverseText('مرحبا'), '0872166','Address'],)
      
      const documentDefinition = {
        content: [
          { text: 'Users', style: 'header' },
          { text: `Project Name: ${current_user.name}`, style: 'body' },
          { canvas: [{ type: 'line', x1: 0, y1: 10, x2: 510, y2: 10, lineWidth: 1 }] },

          {
            
            table: {
              // headers are automatically repeated if the table spans over multiple pages
              // you can declare how many rows should be treated as headers
              headerRows: 1,
              widths: [30,'*', '*', '*'],
              body:body,
              
            },
            style: 'tableStyle',

          }
          
        ],
        defaultStyle: {
          font: 'ArabicFont',
         
        },
        
        styles: {
          
          tableStyle: {
            width: '100%', // Set the width of the table to 100%
            marginTop:20,
            font:'ArabicFont',
            
          },

          header: {
            fontSize:22,
            bold: true,
            alignment: 'center'
          },
          body: {
            fontSize:12,
            bold: true,
            alignment: 'center',
            marginBottom:10,
          },
        },
        
    
        
      };
      return documentDefinition
      
    }

    const download=()=>{
      const documentDefinition=makepdf()
      pdfMake.createPdf(documentDefinition).download('users.pdf');
    }

    const print=()=>{
      const documentDefinition=makepdf()
      pdfMake.createPdf(documentDefinition).print();
    }

    const rowstyle = { height:'10px'}
    const rowClasses = (row, rowIndex) => {
      let classes = null;
      if (row.quantity===0) {
        classes = 'low_quantity';
      }
    
      return classes;
    };
    
  return (
    <div className='p-3'>
        <h1 className='mb-3' style={{fontSize:'1.8rem', fontWeight:'normal'}}>Users</h1>
        <div className='card'>
            <div className='card-header bg-white  d-flex justify-content-between'>
            <h3 className='mb-3' style={{fontSize:'1.3rem', fontWeight:'normal'}}>User List</h3>
                <Button type='button' className='mb-2' variant='outline-success' onClick={()=>setshowmodel(!showmodel)}><PersonAddIcon className='me-1'/>Add User</Button>
            </div>

            <div className='card-body pt-0'>
            <ToolkitProvider
                keyField="id"
                data={ all_users }
                columns={ columns }
                search
                exportCSV
                
                >
                {
                    props => (
                    <div>
                        
                        <div className='d-md-flex justify-content-between align-items-center mt-3'>
                            <div>
                              <ExportCSVButton { ...props.csvProps } className='mb-2 csvbutton  border bg-secondary text-light me-2'>Export CSV</ExportCSVButton>
                              <Button type='button' className='p-1 ps-3 pe-3 me-2 mb-2' variant='outline-primary' onClick={download}><PictureAsPdfIcon/> PDF</Button>
                              <Button type='button' className='p-1 ps-3 pe-3 mb-2' variant='outline-success' onClick={print}><PrintIcon/> Print</Button>
                            </div>
                            <SearchBar { ...props.searchProps } />
                        </div>
                        {isloading && <div className='text-center'><Spinner animation="border" variant="primary" /></div>}
                        <hr />
                        <div>
                          <BootstrapTable
                          { ...props.baseProps }
                          pagination={paginationFactory(options)}
                          rowStyle={ rowstyle}
                          striped
                          bootstrap4
                          condensed
                          rowClasses={ rowClasses }
                          wrapperClasses="table-responsive"
                          />
                        </div>
                        
                    </div>
                    )
                }
            </ToolkitProvider>
            </div>
        </div>

        {showmodel && <Userform
        show={showmodel}
        onHide={()=>setshowmodel(false)}
        user={user}
        route={route}
        callback={dispatch}
        current_user={current_user}
      />}
        {showmodelupdate && <Userupdate
          show={showmodelupdate}
          onHide={()=>setshowmodelupdate(false)}
          data={data}
          user={user}
          route={route}
          fun={custom_toast}
          callback={dispatch}
        /> }
        {delete_user && <Alert_before_delete
          show={delete_user}
          onHide={()=>setdelete_user(false)}
          url={url_to_delete}
          dis_fun={handleconfirm}
          row_id={row_id} 
        />} 
      <ToastContainer autoClose={1000} hideProgressBar={true} theme="dark"/>
    </div>
  )
}

export default User
