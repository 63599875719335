import React, { useState, useEffect, useRef } from "react";
import "./sale.css";
import Button from "react-bootstrap/Button";
import { IconButton } from "@material-ui/core";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  CSVExport,
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import Spinner from "react-bootstrap/Spinner";
import went_wrong_toast from "../alerts/went_wrong_toast";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { ToastContainer } from "react-toastify";
import custom_toast from "../alerts/custom_toast";
import Alert_before_delete from "../../Container/alertContainer";
import { Link } from "react-router-dom";
import success_toast from "../alerts/success_toast";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import { DateRangePicker, defaultStaticRanges } from "react-date-range";
import {
  endOfDay,
  startOfYear,
  endOfYear,
  addMonths,
  addYears,
  isSameDay,
} from "date-fns";
import TextField from "@mui/material/TextField";
import Dropdown from "react-bootstrap/Dropdown";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PrintRoundedIcon from "@material-ui/icons/PrintRounded";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import PrintIcon from "@material-ui/icons/Print";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";

function Sale_history(props) {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  const user = props.state.setuser.user;
  const route = props.state.setuser.route;
  const selected_branch = props.state.Setcurrentinfo.selected_branch;
  const current_user = props.state.Setcurrentinfo.current_user;
  const history = props.state.Settablehistory.table_history;
  const dispatch = props.Settable_history;
  const RemoveUser_fun = props.RemoveUser;
  const settable_data = props.Setsavedata;
  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const [delete_user, setdelete_user] = useState(false);
  const [url_to_delete, seturl_to_delete] = useState("");
  const [row_id, setrow_id] = useState("");
  const [isloading, setisloading] = useState(false);

  const [payment_type, setpayment_type] = useState({
    value: "all",
    label: "All",
  });
  const [customer, setcustomer] = useState({ value: "all", label: "All" });

  const [status, setstatus] = useState({ value: "all", label: "All" });

  const [callagain, setcallagain] = useState(false);

  const [start_date, setstart_date] = useState(
    addMonths(new Date(), -1).toISOString().substring(0, 10)
  );
  const [end_date, setend_date] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const [show, setshow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);
  const [date_range, setdate_range] = useState([
    {
      startDate: addMonths(new Date(), -1),
      endDate: new Date(),
      key: "selection",
      showDateDisplay: "false",
    },
  ]);

  const handleSelect = (item) => {
    const get_date = item.selection;
    setdate_range([item.selection]);
    setstart_date(get_date.startDate.toISOString().substring(0, 10));
    setend_date(get_date.endDate.toISOString().substring(0, 10));
    if (
      get_date.startDate.toISOString().substring(0, 10) !==
      get_date.endDate.toISOString().substring(0, 10)
    ) {
      setshow(!show);
    }
  };

  useEffect(() => {
    setisloading(true);
    dispatch({ type: "Set_menuitem", data: "sale_history" });

    const fetchProducts = async () => {
      var url = `${route}/api/bookings/?user_id=${current_user.id}&start_date=${start_date}&end_date=${end_date}`;
      if (
        date_range[0].endDate.getFullYear() -
          date_range[0].startDate.getFullYear() ===
        10
      ) {
        url = `${route}/api/bookings/?user_id=${current_user.id}`;
      }
      const response = await fetch(`${url}`, {
        headers: { Authorization: `Bearer ${user.access}` },
      });
      const json = await response.json();

      if (response.ok) {
        setisloading(false);
        dispatch({ type: "Set_table_history", data: json });
      }
      if (!response.ok) {
        setisloading(false);
        went_wrong_toast();
        if (json.code === "token_not_valid") {
          RemoveUser_fun();
        }
      }
    };

    if (user) {
      fetchProducts();
    }
  }, [callagain, selected_branch]);

  const handleconfirm = (row) => {
    dispatch({ type: "Delete_table_history", data: { id: row } });
    custom_toast("Delete");
  };

  const dropdown_options_1 = ["outgoing", "pending", "close"];

  const handledropdown = async (e, id) => {
    const status = e.target.innerText;

    const formdata = new FormData();
    formdata.append("status", status);

    const response = await fetch(`${route}/api/bookings/${id}/update-status/`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${user.access}`,
      },
      body: formdata,
    });
    const json = await response.json();

    if (!response.ok) {
      went_wrong_toast();
    }

    if (response.ok) {
      success_toast();
      setcallagain(!callagain);
    }
  };

  const linkFollow = (cell, row, rowIndex, formatExtraData) => {
    return (
      <span className="action d-flex" style={{ width: "10rem" }}>
        <IconButton
          className="border border-primary rounded me-2"
          onClick={() => {
            localStorage.setItem("data", JSON.stringify(row));
            window.open("/invoice/sales", "_blank");
          }}
        >
          <PrintRoundedIcon className="m-1" color="primary" fontSize="medium" />
        </IconButton>

        <IconButton
          className="border border-danger rounded me-2"
          onClick={() => {
            setrow_id(row.id);
            seturl_to_delete(`${route}/api/bookings/${row.id}/`);
            setdelete_user(true);
          }}
        >
          <DeleteRoundedIcon className="m-1" color="error" fontSize="medium" />
        </IconButton>
        <Link to="/sale_edit">
          <IconButton
            className="p-0 me-2"
            style={{ border: "1px solid #003049", borderRadius: "5px" }}
            onClick={() => {
              localStorage.setItem("data", JSON.stringify(row));
            }}
          >
            <EditOutlinedIcon
              className="m-1"
              style={{ color: "#003049" }}
              fontSize="medium"
            />
          </IconButton>
        </Link>
        <span>
          <Dropdown className="mydropdown">
            <Dropdown.Toggle variant="link" id={`dropdown-${row.id}`}>
              <MoreVertIcon
                className="p-0"
                style={{ color: "#003049" }}
                fontSize="medium"
              />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {dropdown_options_1.map((item) => (
                <Dropdown.Item
                  onClick={(e) => {
                    handledropdown(e, row.id);
                  }}
                >
                  {item}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </span>
      </span>
    );
  };

  const headerstyle = (column, colIndex, { sortElement }) => {
    return (
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ minHeight: "2.5rem" }}
      >
        {column.text}
        {sortElement}
      </div>
    );
  };

  const check_status = (cell, row, rowIndex, formatExtraData) => {
    if (cell === "outgoing") {
      return <span className="text-success">{cell}</span>;
    } else if (cell === "close") {
      return <span className="text-danger">{cell}</span>;
    } else if (cell === "pending") {
      return <span className="text-primary">{cell}</span>;
    }
  };

  const products = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div className="d-flex flex-column">
        {row.details &&
          row.details.map((item) => {
            return <span>{item.product_name}</span>;
          })}
      </div>
    );
  };

  const description = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div className="d-flex flex-column">
        {row.details &&
          row.details.map((item) => {
            return <span>{item.product_description}</span>;
          })}
      </div>
    );
  };

  const pending_amount = (cell, row) => {
    var total_ = row.total;
    var amount_received_ = row.amount_received;

    if (!total_) {
      total_ = 0;
    }
    if (!amount_received_) {
      amount_received_ = 0;
    }
    return <div>{total_ - amount_received_}</div>;
  };

  const dater = (cell, row) => {
    if (cell) {
      const splitted_array = cell.split("-");

      return (
        <span>
          {splitted_array[2]}/{splitted_array[1]}/{splitted_array[0]}
        </span>
      );
    }
  };

  const [columns, setcolumns] = useState([
    {
      dataField: "id",
      text: "Id",
      hidden: true,
      headerFormatter: headerstyle,
      csvExport: false,
    },
    {
      dataField: "date",
      text: "Date",
      sort: true,
      hidden: false,
      csvExport: true,
      formatter: dater,
      headerFormatter: headerstyle,
    },
    {
      dataField: "from_date",
      text: "From",
      sort: true,
      formatter: dater,
      hidden: false,
      csvExport: true,
      headerFormatter: headerstyle,
    },
    {
      dataField: "to_date",
      text: "To",
      sort: true,
      formatter: dater,
      hidden: false,
      csvExport: true,
      headerFormatter: headerstyle,
    },
    {
      dataField: "booking_number",
      text: "Booking No.",
      sort: true,
      hidden: false,
      csvExport: true,
      headerFormatter: headerstyle,
    },
    {
      dataField: "detail_product",
      text: "Products",
      formatter: products,
      sort: true,
      hidden: false,
      csvExport: true,
      headerFormatter: headerstyle,
    },
    {
      dataField: "details.product_description",
      text: "Product Descrpt.",
      formatter: description,
      sort: true,
      hidden: false,
      csvExport: true,
      headerFormatter: headerstyle,
    },
    {
      dataField: "customer",
      text: "Customer",
      sort: true,
      hidden: false,
      csvExport: true,
      headerFormatter: headerstyle,
    },
    {
      dataField: "contact",
      text: "Contact",
      sort: true,
      hidden: false,
      csvExport: true,
      headerFormatter: headerstyle,
    },
    {
      dataField: "cnic",
      text: "CNIC",
      sort: true,
      hidden: false,
      csvExport: true,
      headerFormatter: headerstyle,
    },
    {
      dataField: "address",
      text: "Address",
      sort: true,
      hidden: false,
      csvExport: true,
      headerFormatter: headerstyle,
    },
    {
      dataField: "city_name",
      text: "City",
      sort: true,
      hidden: false,
      csvExport: true,
      headerFormatter: headerstyle,
    },
    {
      dataField: "remarks",
      text: "Remarks",
      sort: true,
      hidden: false,
      csvExport: true,
      headerFormatter: headerstyle,
    },
    {
      dataField: "total",
      text: "Total",
      sort: true,
      hidden: false,
      csvExport: true,
      headerFormatter: headerstyle,
    },
    {
      dataField: "amount_received",
      text: "Amount Received",
      sort: true,
      hidden: false,
      csvExport: true,
      headerFormatter: headerstyle,
    },
    {
      dataField: "pending",
      text: "Pending",
      formatter: pending_amount,
      sort: true,
      hidden: false,
      csvExport: true,
      headerFormatter: headerstyle,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: check_status,
      hidden: false,
      csvExport: true,
      headerFormatter: headerstyle,
    },

    {
      dataField: "Edit",
      text: "Action",
      formatter: linkFollow,
      headerFormatter: headerstyle,
      csvExport: false,
    },
  ]);

  const handleselectiochange = (e) => {
    setshow(!show);
    setTarget(e.target);
  };

  useEffect(() => {
    setcallagain(!callagain);
  }, [payment_type, customer, date_range, status]);

  const rowstyle = { height: "10px" };

  const handlechangecolumn = (row) => {
    setcolumns(
      columns.map((item) => {
        if (item.text == row.text) {
          item["hidden"] = !item.hidden;
          item["csvExport"] = !item.csvExport;
        }
        return item;
      })
    );
  };

  return (
    <div className="p-3">
      <h1 className="mb-3" style={{ fontSize: "1.8rem", fontWeight: "normal" }}>
        Booking History
      </h1>
      <ToolkitProvider
        keyField="id"
        data={history}
        columns={columns}
        search
        exportCSV
      >
        {(props) => (
          <div className="card p-3">
            <div className="col-6 col-sm-2">
              {date_range[0].endDate.getFullYear() -
                date_range[0].startDate.getFullYear() ===
              10 ? (
                <TextField
                  ref={ref}
                  type="button"
                  className="form-control  mb-3"
                  label="Date"
                  value="From Start"
                  onClick={handleselectiochange}
                  size="small"
                />
              ) : (
                <TextField
                  ref={ref}
                  type="button"
                  className="form-control  mb-3 "
                  label="Date"
                  value={`${date_range[0].startDate
                    .toLocaleString("en-GB")
                    .substring(0, 10)} - ${date_range[0].endDate
                    .toLocaleString("en-GB")
                    .substring(0, 10)}`}
                  onClick={handleselectiochange}
                  size="small"
                />
              )}
              <Overlay
                show={show}
                target={target}
                placement="bottom-start"
                container={ref}
              >
                <Popover id="popover-contained" className="pop_over">
                  <Popover.Body>
                    <div>
                      <DateRangePicker
                        onChange={handleSelect}
                        showSelectionPreview={true}
                        showCalendarPreview={false}
                        dragSelectionEnabled={true}
                        moveRangeOnFirstSelection={false}
                        months={2}
                        ranges={date_range}
                        direction="horizontal"
                        preventSnapRefocus={true}
                        calendarFocus="backwards"
                        staticRanges={[
                          ...defaultStaticRanges,
                          {
                            label: "Last Year",
                            range: () => ({
                              startDate: startOfYear(addYears(new Date(), -1)),
                              endDate: endOfYear(addYears(new Date(), -1)),
                            }),
                            isSelected(range) {
                              const definedRange = this.range();
                              return (
                                isSameDay(
                                  range.startDate,
                                  definedRange.startDate
                                ) &&
                                isSameDay(range.endDate, definedRange.endDate)
                              );
                            },
                          },
                          {
                            label: "This Year",
                            range: () => ({
                              startDate: startOfYear(new Date()),
                              endDate: endOfDay(new Date()),
                            }),
                            isSelected(range) {
                              const definedRange = this.range();
                              return (
                                isSameDay(
                                  range.startDate,
                                  definedRange.startDate
                                ) &&
                                isSameDay(range.endDate, definedRange.endDate)
                              );
                            },
                          },
                          {
                            label: "From Start",
                            range: () => ({
                              startDate: startOfYear(addYears(new Date(), -10)),
                              endDate: endOfDay(new Date()),
                            }),
                            isSelected(range) {
                              const definedRange = this.range();
                              return (
                                isSameDay(
                                  range.startDate,
                                  definedRange.startDate
                                ) &&
                                isSameDay(range.endDate, definedRange.endDate)
                              );
                            },
                          },
                        ]}
                      />
                    </div>
                  </Popover.Body>
                </Popover>
              </Overlay>
            </div>
            <div className="d-flex justify-content-between align-items-start mt-3 mb-2">
              <div className="d-flex ">
                <ExportCSVButton
                  {...props.csvProps}
                  className="mb-2 p-1 ps-3 pe-3 csvbutton  border bg-secondary text-light me-2"
                >
                  Export CSV
                </ExportCSVButton>

                <Dropdown className="d-inline mx-2  me-2 " autoClose="outside">
                  <Dropdown.Toggle
                    id="dropdown-autoclose-outside"
                    variant="secondary"
                    className="p-1 ps-3 pe-3"
                  >
                    Column Visibility
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {columns.slice(1, 17).map((item) => {
                      return (
                        <Dropdown.Item
                          style={{
                            backgroundColor:
                              item.csvExport && "rgb(92, 153, 228)",
                          }}
                          key={item.text}
                          onClick={() => {
                            handlechangecolumn(item);
                          }}
                        >
                          {item.text}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>

                {/* <Button
                  type="button"
                  className="mb-2 p-1 ps-3 pe-3 me-2"
                  variant="outline-primary"
                  onClick={download}
                >
                  <PictureAsPdfIcon /> PDF
                </Button>
                <Button
                  type="button"
                  className="mb-2 p-1 ps-3 pe-3"
                  variant="outline-success"
                  onClick={print}
                >
                  <PrintIcon /> Print
                </Button> */}
              </div>

              <SearchBar {...props.searchProps} />
            </div>

            {isloading && (
              <div className="text-center">
                <Spinner animation="border" variant="primary" />
              </div>
            )}

            <div style={{ minHeight: "70vh", zoom: "0.65" }}>
              <BootstrapTable
                {...props.baseProps}
                rowStyle={rowstyle}
                striped
                bootstrap4
                condensed
                wrapperClasses="table-responsive"
                classes="history-table"
              />
            </div>
          </div>
        )}
      </ToolkitProvider>

      {delete_user && (
        <Alert_before_delete
          show={delete_user}
          onHide={() => setdelete_user(false)}
          url={url_to_delete}
          dis_fun={handleconfirm}
          row_id={row_id}
        />
      )}
      <ToastContainer autoClose={1000} hideProgressBar={true} theme="dark" />
    </div>
  );
}

export default Sale_history;
