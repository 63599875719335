import React,{useEffect, useState, useRef} from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './product.css'
import "react-toastify/dist/ReactToastify.css";
import TextField from '@mui/material/TextField';
import MenuItem  from '@mui/material/MenuItem';
import ApartmentIcon from '@material-ui/icons/Apartment';
import Select from '../alerts/select';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { Avatar } from '@material-ui/core';
import Badge from '@mui/material/Badge';
import went_wrong_toast from '../alerts/went_wrong_toast';
import success_toast from '../alerts/success_toast';
import Save_button from '../buttons/save_button';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

function Productform({show,onHide,user,route,callback,current_user,check,RemoveUser_fun}) {
    const inputFile = useRef(null)
   
    const [Fileurl , setFileurl] = useState([])
    const [category,setcategory] = useState('')
    const [name , setname] = useState('')
    const [status , setstatus] = useState('')
    const [isloading , setisloading] = useState(false)
    const [all_categories , setall_categories] = useState([])
    const [all_images , setall_images] = useState([])
    
    

    useEffect(() => {
      
      
      

      const fetchcategories = async () => {
        const response = await fetch(`${route}/api/categories/?user_id=${current_user.id}`, {
          
          headers: { 
            Authorization: `Bearer ${user.access}` 
          },
        });
        const json = await response.json();
  
        if (response.ok) {
          setisloading(false)
          const conversion = json.map((item)=>{
            return {value:item.id , label:item.name}
          })
          setall_categories(conversion)
          
        }
        if (!response.ok){
          went_wrong_toast()
          if (json.code==='token_not_valid'){
            RemoveUser_fun()
        }
         
        }
      };
  
      if (user) {
        fetchcategories();
        
        
      }
      
    }, []);

    
     

    const handleSubmit=async(e)=>{
        e.preventDefault()
        
        if (current_user){
          setisloading(true)
        const formData = new FormData();
        formData.append('name', name);
        formData.append('description' , status)
        all_images.forEach((item, index) => {
          
          formData.append(`images[${index}]picture`, item.picture);
        });
        
        formData.append('user', current_user.id);
        


       
        if (category){
          formData.append('category', category.value);
        }else{
          formData.append('category', category);
        }

        
        
        const response= await fetch(`${route}/api/products/`,{
            method: "POST",
            headers: { 
              Authorization : `Bearer ${user.access}`
             },
            body: formData,
        })
        const json=await response.json();
        
        if (!response.ok){
          setisloading(false)
          went_wrong_toast()
      }

      if (response.ok && check==='false'){
        callback({type:'Create_table_history',data: json})
        setisloading(false)
        success_toast()
        setname('')
        setstatus('')
        setcategory('')
        setall_images([])
        
        setFileurl([])
  
        }else if(response.ok && check==='true'){
          callback({type:'Create_table_history',data: json})
          setisloading(false)
          success_toast()
          onHide()

        }
      }}

    const onButtonClick = () => {
      // `current` points to the mounted file input element
      inputFile.current.click();

    };

    const handleimageselection =(event)=>{
      const file = event.target.files[0];
      setall_images([...all_images,{'picture':file}]);
      const reader = new FileReader();
      reader.onload = () => {
        setFileurl([...Fileurl,reader.result]);
        
      };
      reader.readAsDataURL(file);

    }

    const handleClick=(url,file)=>{
      const get_urls = Fileurl.filter((item)=>{
        return item!==url
      })
      const backend_url =all_images.filter((item)=>{
        return item.picture.name!==file.picture.name
      })
    
      setFileurl(get_urls)
      setall_images(backend_url)
      
      

    }

    return (
      <Modal
      show={show}
      onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        style={{zoom:'0.85'}}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className='d-flex align-items-md-center'>
            <ApartmentIcon className='me-2'/>
            Add Product
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
        <form onSubmit={handleSubmit}>

        <Tabs
            defaultActiveKey="Profile"
            transition={false}
            id="noanim-tab-example"
            className="mb-3"
            >
            <Tab eventKey="Profile" title="Profile">

           
              <div className='row'>
                <div className='col-md-6'>
                    <TextField  className='form-control  mb-3' id="outlined-basic" label="Name" value={name} onChange={(e)=>setname(e.target.value)}   size='small'/>
                </div>
                <div className='col-md-6'>
                <Select   options={all_categories} placeholder='Categories' value={category} funct={(e)=>setcategory(e)} required={true}></Select>
                </div>
              </div>
              <div>
              <TextField multiline rows={3} className='form-control   mb-3' id="outlined-basic" label="Description" value={status} onChange={(e)=>setstatus(e.target.value)}   size='small'/>
              </div>
                
            </Tab>
            <Tab eventKey="Images" title="Images" >
            <div className='mb-3'>
                      <input
                      onChange={handleimageselection}
                      id="select-file"
                      type="file"
                      ref={inputFile}
                      style={{display: 'none'}}
                  />
                  <Button onClick={onButtonClick} shadow>
                    Choose file
                  </Button>
                </div>
            <div className='d-flex'>
            { Fileurl.map((item,index)=>{
            
            return (
              <Badge color="error" overlap="circular" badgeContent="X" onClick={()=>{handleClick(item,all_images[index])}}>
                <Avatar src={item} className='mb-2 me-2' style={{width:'100px', height:'100px'}} alt='image'/>
              </Badge>
                            
            
            )}
              )} 
            </div>  
            </Tab>

            
            </Tabs>
            

        
            
              
              <hr></hr>
              <div className=' d-flex flex-row-reverse mt-2 me-2'>
              <Save_button isloading={isloading}/>
              </div>
        </form>
        
        </Modal.Body>
      </Modal>
    );
  }


export default Productform


    